import React from "react"
import { useQuery } from "@apollo/client"
// import { graphql } from "gatsby"
import gql from "graphql-tag"
import Header from "../components/Header/Header"
import PropertyBreadcrumb from "../components/PropertyDetails/PropertyBreadcrumb"
import PropertyBanner from "../components/PropertyDetails/PropertyBanner"
import PropertyDescription from "../components/PropertyDetails/PropertyDescription"
import HolidaySimilarProperties from "../components/PropertyDetails/HolidaySimilarProperties"
import { propertyDetImage } from "../components/Seo/propertyDetailsSEO";
import Footer from "../components/Footer/Footer"
import BrandLogo from "../images/icons/logo_black.svg"
import BookaViewing from "../components/PropertyDetails/BookaViewing"
import SEO from "../components/Seo/seo"
import { capitalize } from "lodash"

const PROPERTY_DETAILS = gql`
  query GetProperty($id: String!) {
    properties(where: { id: $id }) {
      id
      search_type
      status
      department
      publish
      price
      price_qualifier
      currency
      display_address
      area
      bedroom
      bathroom
      reception
      building
      extra
      room_details
      floor_area
      floorarea_min
      floorarea_max
      floorarea_type
      floorplan
      crm_negotiator_id
      latitude
      longitude
      accomadation_summary
      long_description
      images
      imagetransforms
      ggfx_results {
        id
        content_type
        transforms
        src_import_url
        src_cftle
        field
      }
      video_tour
      virtual_tour
    }
  }
`

const HolidayDetailsTemplate = props => {

  const pageurl = typeof window !== "undefined" ? window.location.href : ""
  let myid_filt = pageurl.split("?")
  let mypageurl = myid_filt[0]
  let property_slug = mypageurl.split("-")
  let id = property_slug[property_slug.length - 1]
  id = id.replace("/", "")

  const { loading, error, data } = useQuery(PROPERTY_DETAILS, {
    variables: { id: id },
  })

  // Loading logo
  if (loading)
    return (
      <section className={"loader-wrapper"}>
        <div id="loader-wrapper">
          <div id="loader" className="new-loader">
            <img
              className="logo-white loader-logo"
              src={BrandLogo}
              alt="logo"
            />
          </div>
        </div>
      </section>
    )
  // Loading logo
  //

  return (
    <React.Fragment>

      <div className="property-details-template">
        <Header layout={"property-details-template"} />

        {data && data?.properties && data?.properties.length > 0
          ? data.properties.map((data, i) => {
            const building = data?.building
            var searchaction = data.search_type === "sales"?"for sale":"to let"            
            var metatile = ""+capitalize(building ? building?.replace(/-/g, ' ') : 'holiday home')+" "+searchaction+" with "+data.bedroom+" bedrooms in "+data.display_address+" at AED "+data.price.toLocaleString();
            var metadesc = "Know the details of "+capitalize(building?.replace(/-/g, ' '))+" "+searchaction+" with "+data.bedroom+" bedrooms in "+data.display_address+" at AED "+data.price.toLocaleString()+". Book a viewing to get assistance in finding the right "+capitalize(building?.replace(/-/g, ' '))+" for you.";
            var metaimg = data?.images && data?.images?.length > 0 ? propertyDetImage(data) : "";

            return (
              <>
              <SEO title={metatile} description={metadesc} image={metaimg} />
                  <PropertyBreadcrumb
                    propertyData={data}
                    holidayHomes="holiday-homes"
                  />

                  <PropertyBanner propertyData={data} />

                  <PropertyDescription
                    propertyData={data}
                    holidayHomes="holiday-homes"
                  />

                  <HolidaySimilarProperties holidayData={data} />

                  <BookaViewing propertyData={data} isHolidayhomes />
                </>
              )
            })
          : ""}

        <Footer layout={"property-details"} categoryType="category1" />
      </div>
    </React.Fragment>
  )
}

export default HolidayDetailsTemplate