import React, { useEffect, useState } from "react"

const formImageSrcSet = params => {
  const keys = Object.keys(params.transforms)
  const keysLength = keys.length
  const imageSrcSet = {
    src: params.images[keys[keysLength - 1]],
    srcSet: "",
    sizes: "",
  }
  for (let index = 0; index < keysLength; index++) {
    const Width = keys[index].substr(0, keys[index].search(/[a-z]/i))
    imageSrcSet.srcSet += `${params.images[keys[index]]} ${Width}w, `
    imageSrcSet.sizes += `${params.transforms[keys[index]]} ${Width}px, `
  }
  return imageSrcSet
}

export const SrcSetImage = ({ props }) => {
  // eslint-disable-next-line react/hook-use-state
  const [Imgs, SetImgs] = useState({})
  useEffect(() => {
    if (props.images) {
      const ImageSrcSet = formImageSrcSet(props)
      SetImgs(ImageSrcSet)
    } else {
      SetImgs({})
    }
  }, [props])
  // eslint-disable-next-line jsx-a11y/alt-text
  return <img {...Imgs} {...props.attr} />
}
